import * as React from "react";
import style from "./action-button.module.css";

type Props = {
  label: string;
  url: string;
};

export default function ActionButton({ label, url }: Props) {
  return (
    <div className={style.root}>
      <a href={url} className={style.buttonLink}>
        {label}
      </a>
    </div>
  );
}
