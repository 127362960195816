import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import cn from "classnames";
import InfoModuleLink from "../components/legacy/InfoModuleLink/index";
import API_LOGO from "../../img/legacy/freight-api-logo.svg";
import CARGO_LOGO from "../../img/legacy/cargowise-logo.svg";
import FRONT_LOGO from "../../img/legacy/front-logo.svg";
import EXHANGE_LOGO from "../../img/legacy/exchange-logo.svg";
import OUTLOOK_LOGO from "../../img/legacy/outlook-logo.svg";
import style from "./freight-forwarders.module.css";
import { unwrapFirst } from "../util";
import {
  SectionBullets,
  HelmetSeo,
  Button,
  HorizontalContainer,
  Section,
  Faq,
  FormattedRichText,
  Cols,
  Col,
  Layout,
} from "../components/legacy";
import ActionButton from "../components/legacy/ActionButton";
import { Color } from "../constants/legacy";

type Props = {
  data: GatsbyTypes.FreightForwardersPageQuery;
};

export const query = graphql`
  query FreightForwardersPage {
    prismicFreightForwardersPage {
      data {
        hero_pre_heading
        hero_heading
        hero_description {
          raw
        }

        bullets_sections {
          section_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          section_heading
          section_bullets {
            raw
          }
        }

        tiers_heading
        tiers {
          tier_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          tier_name
          tier_description {
            raw
          }
          tier_button_label
        }

        how_heading
        how_steps {
          how_step_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          how_step_title
          how_step_content {
            raw
          }
        }

        faqs_heading
        faqs {
          faq_question
          faq_answer {
            raw
          }
        }

        cta_text
        cta_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

const BREAKPOINT_HOW = 1020;

export default function FreightForwardersPage({ data }: Props) {
  const page = data.prismicFreightForwardersPage?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout footerBackColor={Color.lightGrey}>
      <HelmetSeo {...page} />

      <div className={style.freightBanner}>
        <div className="container">
          <div className={`row ${style.customRow}`}>
            <div className="col s12">
              <div className={style.freightBannerContent}>
                <h2 className={style.freightTitle}>{page.hero_pre_heading}</h2>
                <h1 className={style.freightHeading}>{page.hero_heading}</h1>
                <div className="row">
                  <div className="col s12">
                    <div className={style.homeSubHeading}>
                      <FormattedRichText
                        render={page.hero_description?.raw}
                        fontSize={18}
                      />
                    </div>

                    <div className="row">
                      <div className={`col s12 ${style.heroCta}`}>
                        <ActionButton label="Request a demo" url="/contact" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {page.bullets_sections?.map(
        (section, i) =>
          section && (
            <SectionBullets
              key={section.section_heading}
              heading={section.section_heading}
              prismicImage={section.section_image}
              prismicBullets={section.section_bullets?.raw}
              reverse={i % 2 === 1}
              dark={i % 2 === 0}
            />
          )
      )}

      {/* Supportd Documents Section */}
      <div className={style.freightDocumentsSection}>
        <div className="container">
          <div className={`row ${style.customRow}`}>
            <div className="col s12">
              <h4 className={style.freightDocumentsHeading}>
                {page.tiers_heading}
              </h4>

              <div className={style.freightDocumentsGroup}>
                {page.tiers?.map(
                  (tier) =>
                    tier && (
                      <InfoModuleLink
                        image={tier.tier_image?.url}
                        heading={tier.tier_name}
                        textRows={(tier.tier_description?.raw as any).map(
                          (p: any) => p.text
                        )}
                      />
                    )
                )}
              </div>

              <div className={style.freightDocumentsCta}>
                <Button label="Request Demo" color="darkBlue" to="/contact" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <StyledSectionHow>
        <HorizontalContainer>
          <h2>{page.how_heading}</h2>
        </HorizontalContainer>

        {page.how_steps?.map((step, i) => {
          if (!step) return null;
          const odd = i % 2 === 1;
          const optional = i === 2;
          const first = i === 0;
          const last = i === 3;
          return (
            <StyledHowRow $optional={optional}>
              <HorizontalContainer>
                <Cols key={i} breakpoint={BREAKPOINT_HOW}>
                  <Col flex={1}>
                    <StyledHowSpine $optional={optional} $last={last}>
                      <div>
                        <span>{i + 1}</span>
                      </div>
                    </StyledHowSpine>
                  </Col>
                  <Col
                    flex={optional ? 2 : 3}
                    joinedCss={odd ? undefined : "order: -1"}
                  >
                    <StyledHowAreaText>
                      {optional && <h3 className="optional">Optional Step</h3>}
                      <h3>{step.how_step_title}</h3>
                      <FormattedRichText
                        render={step.how_step_content?.raw}
                        onDark
                      />
                    </StyledHowAreaText>
                  </Col>
                  <Col
                    flex={optional ? 4 : 3}
                    joinedCss={odd ? "order: -1" : undefined}
                  >
                    <StyledHowAreaImage
                      $optional={optional}
                      $first={first}
                      $last={last}
                    >
                      <img src={step.how_step_image?.url} />
                    </StyledHowAreaImage>
                  </Col>
                </Cols>
              </HorizontalContainer>
            </StyledHowRow>
          );
        })}
      </StyledSectionHow>

      <StyledSectionFaqs>
        <HorizontalContainer>
          <StyledFaqsWrap>
            <div>
              <h2>{page.faqs_heading}</h2>
              {page.faqs?.map((faq) =>
                faq && faq.faq_question && faq.faq_answer ? (
                  <Faq
                    key={faq.faq_question}
                    question={faq.faq_question}
                    answer={faq.faq_answer?.raw}
                  />
                ) : null
              )}
            </div>
          </StyledFaqsWrap>
        </HorizontalContainer>
      </StyledSectionFaqs>

      {/* Integration Section */}
      <div className={style.freightIntegrationSection}>
        <div className="container">
          <div className={`row ${style.customRow}`}>
            <div className="col s12">
              <h4 className={style.freightIntegrationHeading}>
                Integrate directly with…
              </h4>
              <div className={style.freightIntegrationContent}>
                <div className={style.apiDocModule}>
                  <img
                    className={style.apiLogo}
                    src={API_LOGO}
                    alt="Api logo"
                  />
                </div>

                <img
                  className={cn(style.freightIntegrationLogo, style.cargoLogo)}
                  src={CARGO_LOGO}
                  alt="Api logo"
                />
                <img
                  className={cn(style.freightIntegrationLogo, style.frontLogo)}
                  src={FRONT_LOGO}
                  alt="Front logo"
                />
                <img
                  className={cn(
                    style.freightIntegrationLogo,
                    style.exchangeLogo
                  )}
                  src={EXHANGE_LOGO}
                  alt="Exchange logo"
                />
                <img
                  className={cn(
                    style.freightIntegrationLogo,
                    style.outlookLogo
                  )}
                  src={OUTLOOK_LOGO}
                  alt="Outlook logo"
                />
              </div>

              <div className={style.freightIntegrationContentDesktop}>
                <img
                  className={cn(style.freightIntegrationLogo, style.cargoLogo)}
                  src={CARGO_LOGO}
                  alt="Api logo"
                />
                <img
                  className={cn(style.freightIntegrationLogo, style.frontLogo)}
                  src={FRONT_LOGO}
                  alt="Front logo"
                />
                <div className={style.apiDocModule}>
                  <img
                    className={style.apiLogo}
                    src={API_LOGO}
                    alt="Api logo"
                  />
                </div>
                <img
                  className={cn(
                    style.freightIntegrationLogo,
                    style.exchangeLogo
                  )}
                  src={EXHANGE_LOGO}
                  alt="Exchange logo"
                />
                <img
                  className={cn(
                    style.freightIntegrationLogo,
                    style.outlookLogo
                  )}
                  src={OUTLOOK_LOGO}
                  alt="Outlook logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const StyledSectionHow = styled(Section)`
  background-color: ${Color.blue};
  color: ${Color.white};

  h2 {
    text-align: center;
    font-size: 44px;
    margin-bottom: 80px;

    @media (max-width: ${BREAKPOINT_HOW}px) {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: ${BREAKPOINT_HOW}px) {
    text-align: center;
  }
`;

const StyledHowRow = styled("div")<{ $optional?: boolean }>`
  background-color: ${(p) => (p.$optional ? Color.darkBlue : Color.blue)};
  padding: ${(p) => (p.$optional ? "40px 0" : 0)};
  margin: ${(p) => (p.$optional ? "40px 0" : 0)};

  @media (max-width: ${BREAKPOINT_HOW}px) {
    margin-bottom: 0;
    padding-top: 0;
  }
`;

const StyledHowAreaText = styled.div`
  @media (max-width: ${BREAKPOINT_HOW}px) {
    margin: 10px 0 40px;
  }

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 24px;
    color: ${Color.orange};
    font-weight: 600;
  }

  h3.optional {
    font-size: 32px;
    color: ${Color.white};
  }
`;

const StyledHowAreaImage = styled("div")<{
  $optional: boolean;
  $first: boolean;
  $last: boolean;
}>`
  margin-top: ${(p) => (!p.$optional && !p.$first ? "40px" : 0)};
  margin-bottom: ${(p) => (!p.$optional && !p.$last ? "40px" : 0)};
  @media (max-width: ${BREAKPOINT_HOW}px) {
    margin: 0;
  }
`;

const StyledHowSpine = styled("div")<{ $optional?: boolean; $last?: boolean }>`
  height: ${(p) => (p.$last ? "50%" : "100%")};
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 80px;

  @media (max-width: ${BREAKPOINT_HOW}px) {
    height: 100%;
  }

  > div {
    width: 0;
    border-left: 1px ${Color.lightGrey}
      ${(p) => (p.$optional ? "dashed" : "solid")};
    position: relative;

    > span {
      position: absolute;
      top: ${(p) => (p.$last ? "100%" : "50%")};
      left: -15px;
      height: 30px;
      width: 30px;
      background-color: ${Color.orange};
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
      color: ${Color.darkBlue};
      font-size: 21px;
      font-weight: 700;

      @media (max-width: ${BREAKPOINT_HOW}px) {
        top: 50%;
      }
    }
  }
`;

const StyledSectionFaqs = styled(Section)`
  background-color: ${Color.darkBlue};
`;

const StyledFaqsWrap = styled.div`
  display: flex;
  justify-content: center;

  > div {
    max-width: 526px;
    color: #fff;
  }

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;
