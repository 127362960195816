import * as React from "react";
import { Link } from "gatsby";
import style from "./info-module-link.module.css";

type Props = {
  image?: string;
  imageAlt?: string;
  heading?: string;
  textRows?: string[];
  textRowOne?: string;
  textRowTwo?: string;
  btnLink?: string;
  btnLabel?: string;
};

export default function InfoModuleLink({
  image = "",
  imageAlt = "",
  heading = "",
  textRows = [],
  btnLink = "",
  btnLabel = "",
}) {
  return (
    <div className={style.root}>
      {image && (
        <img className={style.infoModuleLinkImg} src={image} alt={imageAlt} />
      )}

      <div className={style.infoModuleLinkTextWrapper}>
        {heading && <h4 className={style.infoModuleLinkHeading}>{heading}</h4>}

        <div className={style.infoModuleLinkTextGroup}>
          {textRows.map((textRow) => (
            <p key={textRow} className={style.infoModuleLinkText}>
              {textRow}
            </p>
          ))}
        </div>

        {btnLink && (
          <Link className={style.infoModuleLinkButton} to={`/${btnLink}`}>
            {btnLabel}
          </Link>
        )}
      </div>
    </div>
  );
}
